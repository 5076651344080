var exports = {};

/**
 * Wraps a GeoJSON {@link Geometry} in a GeoJSON {@link Feature}.
 *
 * @name feature
 * @param {Geometry} geometry input geometry
 * @param {Object} [properties={}] an Object of key-value pairs to add as properties
 * @param {Array<number>} [bbox] BBox [west, south, east, north]
 * @param {string|number} [id] Identifier
 * @returns {Feature} a GeoJSON Feature
 * @example
 * var geometry = {
 *   "type": "Point",
 *   "coordinates": [110, 50]
 * };
 *
 * var feature = turf.feature(geometry);
 *
 * //=feature
 */
function feature(geometry, properties, bbox, id) {
  if (geometry === undefined) throw new Error("geometry is required");
  if (properties && properties.constructor !== Object) throw new Error("properties must be an Object");
  if (bbox && bbox.length !== 4) throw new Error("bbox must be an Array of 4 numbers");
  if (id && ["string", "number"].indexOf(typeof id) === -1) throw new Error("id must be a number or a string");
  var feat = {
    type: "Feature"
  };
  if (id) feat.id = id;
  if (bbox) feat.bbox = bbox;
  feat.properties = properties || {};
  feat.geometry = geometry;
  return feat;
}
/**
 * Creates a GeoJSON {@link Geometry} from a Geometry string type & coordinates.
 * For GeometryCollection type use `helpers.geometryCollection`
 *
 * @name geometry
 * @param {string} type Geometry Type
 * @param {Array<number>} coordinates Coordinates
 * @param {Array<number>} [bbox] BBox [west, south, east, north]
 * @returns {Geometry} a GeoJSON Geometry
 * @example
 * var type = 'Point';
 * var coordinates = [110, 50];
 *
 * var geometry = turf.geometry(type, coordinates);
 *
 * //=geometry
 */


function geometry(type, coordinates, bbox) {
  // Validation
  if (!type) throw new Error("type is required");
  if (!coordinates) throw new Error("coordinates is required");
  if (!Array.isArray(coordinates)) throw new Error("coordinates must be an Array");
  if (bbox && bbox.length !== 4) throw new Error("bbox must be an Array of 4 numbers");
  var geom;

  switch (type) {
    case "Point":
      geom = point(coordinates).geometry;
      break;

    case "LineString":
      geom = lineString(coordinates).geometry;
      break;

    case "Polygon":
      geom = polygon(coordinates).geometry;
      break;

    case "MultiPoint":
      geom = multiPoint(coordinates).geometry;
      break;

    case "MultiLineString":
      geom = multiLineString(coordinates).geometry;
      break;

    case "MultiPolygon":
      geom = multiPolygon(coordinates).geometry;
      break;

    default:
      throw new Error(type + " is invalid");
  }

  if (bbox) geom.bbox = bbox;
  return geom;
}
/**
 * Takes coordinates and properties (optional) and returns a new {@link Point} feature.
 *
 * @name point
 * @param {Array<number>} coordinates longitude, latitude position (each in decimal degrees)
 * @param {Object} [properties={}] an Object of key-value pairs to add as properties
 * @param {Array<number>} [bbox] BBox [west, south, east, north]
 * @param {string|number} [id] Identifier
 * @returns {Feature<Point>} a Point feature
 * @example
 * var point = turf.point([-75.343, 39.984]);
 *
 * //=point
 */


function point(coordinates, properties, bbox, id) {
  if (!coordinates) throw new Error("No coordinates passed");
  if (coordinates.length === undefined) throw new Error("Coordinates must be an array");
  if (coordinates.length < 2) throw new Error("Coordinates must be at least 2 numbers long");
  if (!isNumber(coordinates[0]) || !isNumber(coordinates[1])) throw new Error("Coordinates must contain numbers");
  return feature({
    type: "Point",
    coordinates: coordinates
  }, properties, bbox, id);
}
/**
 * Takes an array of LinearRings and optionally an {@link Object} with properties and returns a {@link Polygon} feature.
 *
 * @name polygon
 * @param {Array<Array<Array<number>>>} coordinates an array of LinearRings
 * @param {Object} [properties={}] an Object of key-value pairs to add as properties
 * @param {Array<number>} [bbox] BBox [west, south, east, north]
 * @param {string|number} [id] Identifier
 * @returns {Feature<Polygon>} a Polygon feature
 * @throws {Error} throw an error if a LinearRing of the polygon has too few positions
 * or if a LinearRing of the Polygon does not have matching Positions at the beginning & end.
 * @example
 * var polygon = turf.polygon([[
 *   [-2.275543, 53.464547],
 *   [-2.275543, 53.489271],
 *   [-2.215118, 53.489271],
 *   [-2.215118, 53.464547],
 *   [-2.275543, 53.464547]
 * ]], { name: 'poly1', population: 400});
 *
 * //=polygon
 */


function polygon(coordinates, properties, bbox, id) {
  if (!coordinates) throw new Error("No coordinates passed");

  for (var i = 0; i < coordinates.length; i++) {
    var ring = coordinates[i];

    if (ring.length < 4) {
      throw new Error("Each LinearRing of a Polygon must have 4 or more Positions.");
    }

    for (var j = 0; j < ring[ring.length - 1].length; j++) {
      // Check if first point of Polygon contains two numbers
      if (i === 0 && j === 0 && !isNumber(ring[0][0]) || !isNumber(ring[0][1])) throw new Error("Coordinates must contain numbers");

      if (ring[ring.length - 1][j] !== ring[0][j]) {
        throw new Error("First and last Position are not equivalent.");
      }
    }
  }

  return feature({
    type: "Polygon",
    coordinates: coordinates
  }, properties, bbox, id);
}
/**
 * Creates a {@link LineString} based on a
 * coordinate array. Properties can be added optionally.
 *
 * @name lineString
 * @param {Array<Array<number>>} coordinates an array of Positions
 * @param {Object} [properties={}] an Object of key-value pairs to add as properties
 * @param {Array<number>} [bbox] BBox [west, south, east, north]
 * @param {string|number} [id] Identifier
 * @returns {Feature<LineString>} a LineString feature
 * @throws {Error} if no coordinates are passed
 * @example
 * var linestring1 = turf.lineString([
 *   [-21.964416, 64.148203],
 *   [-21.956176, 64.141316],
 *   [-21.93901, 64.135924],
 *   [-21.927337, 64.136673]
 * ]);
 * var linestring2 = turf.lineString([
 *   [-21.929054, 64.127985],
 *   [-21.912918, 64.134726],
 *   [-21.916007, 64.141016],
 *   [-21.930084, 64.14446]
 * ], {name: 'line 1', distance: 145});
 *
 * //=linestring1
 *
 * //=linestring2
 */


function lineString(coordinates, properties, bbox, id) {
  if (!coordinates) throw new Error("No coordinates passed");
  if (coordinates.length < 2) throw new Error("Coordinates must be an array of two or more positions"); // Check if first point of LineString contains two numbers

  if (!isNumber(coordinates[0][1]) || !isNumber(coordinates[0][1])) throw new Error("Coordinates must contain numbers");
  return feature({
    type: "LineString",
    coordinates: coordinates
  }, properties, bbox, id);
}
/**
 * Takes one or more {@link Feature|Features} and creates a {@link FeatureCollection}.
 *
 * @name featureCollection
 * @param {Feature[]} features input features
 * @param {Array<number>} [bbox] BBox [west, south, east, north]
 * @param {string|number} [id] Identifier
 * @returns {FeatureCollection} a FeatureCollection of input features
 * @example
 * var features = [
 *  turf.point([-75.343, 39.984], {name: 'Location A'}),
 *  turf.point([-75.833, 39.284], {name: 'Location B'}),
 *  turf.point([-75.534, 39.123], {name: 'Location C'})
 * ];
 *
 * var collection = turf.featureCollection(features);
 *
 * //=collection
 */


function featureCollection(features, bbox, id) {
  if (!features) throw new Error("No features passed");
  if (!Array.isArray(features)) throw new Error("features must be an Array");
  if (bbox && bbox.length !== 4) throw new Error("bbox must be an Array of 4 numbers");
  if (id && ["string", "number"].indexOf(typeof id) === -1) throw new Error("id must be a number or a string");
  var fc = {
    type: "FeatureCollection"
  };
  if (id) fc.id = id;
  if (bbox) fc.bbox = bbox;
  fc.features = features;
  return fc;
}
/**
 * Creates a {@link Feature<MultiLineString>} based on a
 * coordinate array. Properties can be added optionally.
 *
 * @name multiLineString
 * @param {Array<Array<Array<number>>>} coordinates an array of LineStrings
 * @param {Object} [properties={}] an Object of key-value pairs to add as properties
 * @param {Array<number>} [bbox] BBox [west, south, east, north]
 * @param {string|number} [id] Identifier
 * @returns {Feature<MultiLineString>} a MultiLineString feature
 * @throws {Error} if no coordinates are passed
 * @example
 * var multiLine = turf.multiLineString([[[0,0],[10,10]]]);
 *
 * //=multiLine
 */


function multiLineString(coordinates, properties, bbox, id) {
  if (!coordinates) throw new Error("No coordinates passed");
  return feature({
    type: "MultiLineString",
    coordinates: coordinates
  }, properties, bbox, id);
}
/**
 * Creates a {@link Feature<MultiPoint>} based on a
 * coordinate array. Properties can be added optionally.
 *
 * @name multiPoint
 * @param {Array<Array<number>>} coordinates an array of Positions
 * @param {Object} [properties={}] an Object of key-value pairs to add as properties
 * @param {Array<number>} [bbox] BBox [west, south, east, north]
 * @param {string|number} [id] Identifier
 * @returns {Feature<MultiPoint>} a MultiPoint feature
 * @throws {Error} if no coordinates are passed
 * @example
 * var multiPt = turf.multiPoint([[0,0],[10,10]]);
 *
 * //=multiPt
 */


function multiPoint(coordinates, properties, bbox, id) {
  if (!coordinates) throw new Error("No coordinates passed");
  return feature({
    type: "MultiPoint",
    coordinates: coordinates
  }, properties, bbox, id);
}
/**
 * Creates a {@link Feature<MultiPolygon>} based on a
 * coordinate array. Properties can be added optionally.
 *
 * @name multiPolygon
 * @param {Array<Array<Array<Array<number>>>>} coordinates an array of Polygons
 * @param {Object} [properties={}] an Object of key-value pairs to add as properties
 * @param {Array<number>} [bbox] BBox [west, south, east, north]
 * @param {string|number} [id] Identifier
 * @returns {Feature<MultiPolygon>} a multipolygon feature
 * @throws {Error} if no coordinates are passed
 * @example
 * var multiPoly = turf.multiPolygon([[[[0,0],[0,10],[10,10],[10,0],[0,0]]]]);
 *
 * //=multiPoly
 *
 */


function multiPolygon(coordinates, properties, bbox, id) {
  if (!coordinates) throw new Error("No coordinates passed");
  return feature({
    type: "MultiPolygon",
    coordinates: coordinates
  }, properties, bbox, id);
}
/**
 * Creates a {@link Feature<GeometryCollection>} based on a
 * coordinate array. Properties can be added optionally.
 *
 * @name geometryCollection
 * @param {Array<Geometry>} geometries an array of GeoJSON Geometries
 * @param {Object} [properties={}] an Object of key-value pairs to add as properties
 * @param {Array<number>} [bbox] BBox [west, south, east, north]
 * @param {string|number} [id] Identifier
 * @returns {Feature<GeometryCollection>} a GeoJSON GeometryCollection Feature
 * @example
 * var pt = {
 *     "type": "Point",
 *       "coordinates": [100, 0]
 *     };
 * var line = {
 *     "type": "LineString",
 *     "coordinates": [ [101, 0], [102, 1] ]
 *   };
 * var collection = turf.geometryCollection([pt, line]);
 *
 * //=collection
 */


function geometryCollection(geometries, properties, bbox, id) {
  if (!geometries) throw new Error("geometries is required");
  if (!Array.isArray(geometries)) throw new Error("geometries must be an Array");
  return feature({
    type: "GeometryCollection",
    geometries: geometries
  }, properties, bbox, id);
} // https://en.wikipedia.org/wiki/Great-circle_distance#Radius_for_spherical_Earth


var factors = {
  miles: 3960,
  nauticalmiles: 3441.145,
  degrees: 57.2957795,
  radians: 1,
  inches: 250905600,
  yards: 6969600,
  meters: 6373000,
  metres: 6373000,
  centimeters: 637300000,
  centimetres: 637300000,
  kilometers: 6373,
  kilometres: 6373,
  feet: 20908792.65
};
var areaFactors = {
  kilometers: 0.000001,
  kilometres: 0.000001,
  meters: 1,
  metres: 1,
  centimetres: 10000,
  millimeter: 1000000,
  acres: 0.000247105,
  miles: 3.86e-7,
  yards: 1.195990046,
  feet: 10.763910417,
  inches: 1550.003100006
};
/**
 * Round number to precision
 *
 * @param {number} num Number
 * @param {number} [precision=0] Precision
 * @returns {number} rounded number
 * @example
 * turf.round(120.4321)
 * //=120
 *
 * turf.round(120.4321, 2)
 * //=120.43
 */

function round(num, precision) {
  if (num === undefined || num === null || isNaN(num)) throw new Error("num is required");
  if (precision && !(precision >= 0)) throw new Error("precision must be a positive number");
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(num * multiplier) / multiplier;
}
/**
 * Convert a distance measurement (assuming a spherical Earth) from radians to a more friendly unit.
 * Valid units: miles, nauticalmiles, inches, yards, meters, metres, kilometers, centimeters, feet
 *
 * @name radiansToDistance
 * @param {number} radians in radians across the sphere
 * @param {string} [units=kilometers] can be degrees, radians, miles, or kilometers inches, yards, metres, meters, kilometres, kilometers.
 * @returns {number} distance
 */


function radiansToDistance(radians, units) {
  if (radians === undefined || radians === null) throw new Error("radians is required");
  var factor = factors[units || "kilometers"];
  if (!factor) throw new Error("units is invalid");
  return radians * factor;
}
/**
 * Convert a distance measurement (assuming a spherical Earth) from a real-world unit into radians
 * Valid units: miles, nauticalmiles, inches, yards, meters, metres, kilometers, centimeters, feet
 *
 * @name distanceToRadians
 * @param {number} distance in real units
 * @param {string} [units=kilometers] can be degrees, radians, miles, or kilometers inches, yards, metres, meters, kilometres, kilometers.
 * @returns {number} radians
 */


function distanceToRadians(distance, units) {
  if (distance === undefined || distance === null) throw new Error("distance is required");
  var factor = factors[units || "kilometers"];
  if (!factor) throw new Error("units is invalid");
  return distance / factor;
}
/**
 * Convert a distance measurement (assuming a spherical Earth) from a real-world unit into degrees
 * Valid units: miles, nauticalmiles, inches, yards, meters, metres, centimeters, kilometres, feet
 *
 * @name distanceToDegrees
 * @param {number} distance in real units
 * @param {string} [units=kilometers] can be degrees, radians, miles, or kilometers inches, yards, metres, meters, kilometres, kilometers.
 * @returns {number} degrees
 */


function distanceToDegrees(distance, units) {
  return radians2degrees(distanceToRadians(distance, units));
}
/**
 * Converts any bearing angle from the north line direction (positive clockwise)
 * and returns an angle between 0-360 degrees (positive clockwise), 0 being the north line
 *
 * @name bearingToAngle
 * @param {number} bearing angle, between -180 and +180 degrees
 * @returns {number} angle between 0 and 360 degrees
 */


function bearingToAngle(bearing) {
  if (bearing === null || bearing === undefined) throw new Error("bearing is required");
  var angle = bearing % 360;
  if (angle < 0) angle += 360;
  return angle;
}
/**
 * Converts an angle in radians to degrees
 *
 * @name radians2degrees
 * @param {number} radians angle in radians
 * @returns {number} degrees between 0 and 360 degrees
 */


function radians2degrees(radians) {
  if (radians === null || radians === undefined) throw new Error("radians is required");
  var degrees = radians % (2 * Math.PI);
  return degrees * 180 / Math.PI;
}
/**
 * Converts an angle in degrees to radians
 *
 * @name degrees2radians
 * @param {number} degrees angle between 0 and 360 degrees
 * @returns {number} angle in radians
 */


function degrees2radians(degrees) {
  if (degrees === null || degrees === undefined) throw new Error("degrees is required");
  var radians = degrees % 360;
  return radians * Math.PI / 180;
}
/**
 * Converts a distance to the requested unit.
 * Valid units: miles, nauticalmiles, inches, yards, meters, metres, kilometers, centimeters, feet
 *
 * @param {number} distance to be converted
 * @param {string} originalUnit of the distance
 * @param {string} [finalUnit=kilometers] returned unit
 * @returns {number} the converted distance
 */


function convertDistance(distance, originalUnit, finalUnit) {
  if (distance === null || distance === undefined) throw new Error("distance is required");
  if (!(distance >= 0)) throw new Error("distance must be a positive number");
  var convertedDistance = radiansToDistance(distanceToRadians(distance, originalUnit), finalUnit || "kilometers");
  return convertedDistance;
}
/**
 * Converts a area to the requested unit.
 * Valid units: kilometers, kilometres, meters, metres, centimetres, millimeter, acre, mile, yard, foot, inch
 * @param {number} area to be converted
 * @param {string} [originalUnit=meters] of the distance
 * @param {string} [finalUnit=kilometers] returned unit
 * @returns {number} the converted distance
 */


function convertArea(area, originalUnit, finalUnit) {
  if (area === null || area === undefined) throw new Error("area is required");
  if (!(area >= 0)) throw new Error("area must be a positive number");
  var startFactor = areaFactors[originalUnit || "meters"];
  if (!startFactor) throw new Error("invalid original units");
  var finalFactor = areaFactors[finalUnit || "kilometers"];
  if (!finalFactor) throw new Error("invalid final units");
  return area / startFactor * finalFactor;
}
/**
 * isNumber
 *
 * @param {*} num Number to validate
 * @returns {boolean} true/false
 * @example
 * turf.isNumber(123)
 * //=true
 * turf.isNumber('foo')
 * //=false
 */


function isNumber(num) {
  return !isNaN(num) && num !== null && !Array.isArray(num);
}

exports = {
  feature: feature,
  geometry: geometry,
  featureCollection: featureCollection,
  geometryCollection: geometryCollection,
  point: point,
  multiPoint: multiPoint,
  lineString: lineString,
  multiLineString: multiLineString,
  polygon: polygon,
  multiPolygon: multiPolygon,
  radiansToDistance: radiansToDistance,
  distanceToRadians: distanceToRadians,
  distanceToDegrees: distanceToDegrees,
  radians2degrees: radians2degrees,
  degrees2radians: degrees2radians,
  bearingToAngle: bearingToAngle,
  convertDistance: convertDistance,
  convertArea: convertArea,
  round: round,
  isNumber: isNumber
};
export default exports;
const _feature = exports.feature,
      _geometry = exports.geometry,
      _featureCollection = exports.featureCollection,
      _geometryCollection = exports.geometryCollection,
      _point = exports.point,
      _multiPoint = exports.multiPoint,
      _lineString = exports.lineString,
      _multiLineString = exports.multiLineString,
      _polygon = exports.polygon,
      _multiPolygon = exports.multiPolygon,
      _radiansToDistance = exports.radiansToDistance,
      _distanceToRadians = exports.distanceToRadians,
      _distanceToDegrees = exports.distanceToDegrees,
      _radians2degrees = exports.radians2degrees,
      _degrees2radians = exports.degrees2radians,
      _bearingToAngle = exports.bearingToAngle,
      _convertDistance = exports.convertDistance,
      _convertArea = exports.convertArea,
      _round = exports.round,
      _isNumber = exports.isNumber;
export { _feature as feature, _geometry as geometry, _featureCollection as featureCollection, _geometryCollection as geometryCollection, _point as point, _multiPoint as multiPoint, _lineString as lineString, _multiLineString as multiLineString, _polygon as polygon, _multiPolygon as multiPolygon, _radiansToDistance as radiansToDistance, _distanceToRadians as distanceToRadians, _distanceToDegrees as distanceToDegrees, _radians2degrees as radians2degrees, _degrees2radians as degrees2radians, _bearingToAngle as bearingToAngle, _convertDistance as convertDistance, _convertArea as convertArea, _round as round, _isNumber as isNumber };